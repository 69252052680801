import { template as template_b9af2893a1b54b0e9ae228960c7bcd17 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_b9af2893a1b54b0e9ae228960c7bcd17(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
