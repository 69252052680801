import { template as template_32af7de6156c4c88af78f4ed12892b8e } from "@ember/template-compiler";
const FKControlMenuContainer = template_32af7de6156c4c88af78f4ed12892b8e(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
